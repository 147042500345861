













































import { mapActions, mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import UserSubscription from '@/calendesk/models/DTO/Response/UserSubscription'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import PayForBookingsWithUserSubscriptionRequestData
  from '@/calendesk/models/DTO/Request/PayForBookingsWithUserSubscriptionRequestData'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import BasicUserSubscription from '@/calendesk/models/DTO/Response/BasicUserSubscription'

export default mixins(DraftElement).extend({
  name: 'PayWithSubscriptionDialog',
  data () {
    return {
      selectedSubscriptionId: null as number | null
    }
  },
  computed: {
    ...mapGetters({
      confirmDialog: 'dialog/getConfirmDialog',
      confirmDialogLoader: 'dialog/getConfirmDialogLoader'
    }),
    acceptedSubscriptions (): UserSubscription[] {
      if (this.hasUserSubscriptions) {
        return this.user.subscriptions
          .filter((userSubscription: BasicUserSubscription) => userSubscription.isActive())
          .sort((a: BasicUserSubscription, b: BasicUserSubscription) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      }

      return []
    }
  },
  methods: {
    ...mapActions({
      closeConfirmDialog: 'dialog/closeConfirmDialog',
      payForBookingsWithUserSubscription: 'subscription/payForBookingsWithUserSubscription'
    }),
    async confirmAction () {
      if (this.selectedSubscriptionId) {
        try {
          this.setConfirmDialogLoader(true)

          const paidIds = await this.payForBookingsWithUserSubscription(new PayForBookingsWithUserSubscriptionRequestData(this.confirmDialog.data.bookingIds, this.selectedSubscriptionId))
          this.confirmDialog.confirmHandler(paidIds)
        } catch (error) {
          errorNotification(null, error)
        }

        this.closeConfirmDialog()
        this.setConfirmDialogLoader(false)
      }
    },
    getSubscriptionText (userSubscription: UserSubscription) {
      return userSubscription.subscription.name + ` (${this.$moment(userSubscription.createdAt).format(DateTimeFormats.FULL_WITH_MONTH_TEXT_AND_TIME)} [${userSubscription.id}])`
    }
  }
})
